export enum ProjectStatus {
  Published = 'published',
  Draft = 'draft',
  Amended = 'amended',
  Processing = 'processing',
}

export enum ProjectKind {
  Html = 'html',
  Image = 'image',
}

export const PROJECT_CARD = 'project-card';

export const DEMO_FOLDER = 'demo-folder';
