import {
  ButtonHTMLAttributes,
  CSSProperties,
  DetailedHTMLProps,
  FC,
} from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import { Variant } from '../../features/ui/ui.types';
import styles from './Button.module.css';

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  rounded?: boolean;
  fullWidth?: boolean;
  variant?:
    | Variant
    | 'blue'
    | 'magenta-outline'
    | 'purple-outline'
    | 'yellow-outline'
    | 'green-outline'
    | 'red'
    | 'purpleInverted'
    | 'inline'
    | 'outline'
    | 'yellow-secondary'
    | 'dark-blue'
    | 'light-grey'
    | 'grey';
  icon?: string; // TODO: add typings for possible icon names
  /**
   * @deprecated DO NOT USE. Component should be isolated and have no "dangerous" in-place styles
   */
  iconClassName?: string;
  /**
   * @deprecated DO NOT USE. Component should be isolated and have no "dangerous" in-place styles
   */
  iconStyle?: CSSProperties;
}

const Button: FC<ButtonProps> = (props) => {
  const {
    children,
    size = 'md',
    rounded = true,
    variant = 'magenta',
    icon,
    iconClassName,
    iconStyle,
    className,
    type = 'button',
    fullWidth,
    ...restProps
  } = props;

  return (
    <button
      type={type}
      {...restProps}
      className={cx(
        styles.button,
        {
          [styles.buttonLarge]: size === 'lg',
          [styles.buttonExtraLarge]: size === 'xl',
          [styles.buttonSmall]: size === 'sm',
          [styles.buttonRounded]: rounded,
          [styles.buttonMagenta]: variant === 'magenta',
          [styles.buttonRed]: variant === 'red',
          [styles.buttonMagentaOutline]: variant === 'magenta-outline',
          [styles.buttonPurpleOutline]: variant === 'purple-outline',
          [styles.buttonYellow]: variant === 'yellow',
          [styles.buttonGreen]: variant === 'green',
          [styles.buttonPurple]: variant === 'purple',
          [styles.buttonBlue]: variant === 'blue',
          [styles.buttonPurpleInverted]: variant === 'purpleInverted',
          [styles.buttonOutline]: variant === 'outline',
          [styles.buttonInline]: variant === 'inline',
          [styles.buttonFullWidth]: fullWidth,
          [styles.buttonYellowOutline]: variant === 'yellow-outline',
          [styles.buttonGreenOutline]: variant === 'green-outline',
          [styles.buttonYellowSecondary]: variant === 'yellow-secondary',
          [styles.buttonDarkBlue]: variant === 'dark-blue',
          [styles.buttonLightGrey]: variant === 'light-grey',
          [styles.buttonGrey]: variant === 'grey',
        },
        className
      )}
    >
      {icon && (
        <Icon
          name={icon}
          className={cx(styles.icon, iconClassName)}
          style={iconStyle}
        />
      )}
      {children && <span className={styles.children}>{children}</span>}
    </button>
  );
};

export default Button;
