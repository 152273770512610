import {
  cloneElement,
  FC,
  ReactElement,
  SVGProps,
  useEffect,
  useState,
} from 'react';

export interface IconProps extends SVGProps<SVGSVGElement> {
  name: string;
}

const Icon: FC<IconProps> = (props) => {
  const { name, width = undefined, height = undefined, ...restProps } = props;

  const [iconModule, setIconModule] = useState<ReactElement | null>(() => (
    <svg viewBox="0 0 100 100" {...restProps} />
  ));

  useEffect(() => {
    import(`../../assets/icons/${name}.svg`)
      .then((module) => {
        // Prefer ReactComponent if available (for Vite)
        if (module.ReactComponent) {
          setIconModule(module.ReactComponent({}));
        }
        // Otherwise, use default export (for Next.js)
        else if (module.default) {
          setIconModule(module.default({}));
        } else {
          console.error('Unexpected module structure for SVG:', module);
        }
      })
      .catch(() => console.error(`Icon with name: ${name} not found!`));
  }, [name]);

  useEffect(() => {
    return () => {
      setIconModule(<></>);
    };
  }, []);

  if (!iconModule) {
    return null;
  }

  return cloneElement(iconModule, { ...restProps, width, height });
};

export default Icon;
