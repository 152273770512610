/**
 * @see {@link https://storylane.atlassian.net/l/cp/iocs2rnX Docs}
 */
export enum PublicDemoQueryParams {
  HideAll = 'hide_all',
  HideForms = 'hide_forms',
  HideSteps = 'hide_steps',
  Embed = 'embed',
  Flow = 'flow',
  Token = 'token',
  Scale = 'scale',
  Animation = 'animation',
  AutoPlay = 'auto_play',
  Recording = 'recording',
}

/**
 * @see {@link https://storylane.atlassian.net/l/cp/xE1nPWV1 Docs}
 */
export enum CrossOriginSessionEventEvent {
  ProjectResolved = 'demo_open',
  WidgetReady = 'step_rendered',
  WidgetChanged = 'step_view',
  WidgetCtaClick = 'primary_cta',
  WidgetSecondaryClick = 'secondary_cta',
  FlowChanged = 'flow_start',
  FlowEnd = 'flow_end',
  PageChanged = 'page_view',
  LeadCaptured = 'lead_identify',
  OpenedExternalUrl = 'open_external_url',
  FlowlistItemClick = 'checklist_item_view',
  DemoFinished = 'demo_finished',
  RecordingStart = 'demo_recording_start',
  CustomCta = 'convert_cta',
}

export enum ThirdPartyAnalyticsEvent {
  ProjectResolved = 'sl_demo_open',
  WidgetChanged = 'sl_step_view',
  WidgetCtaClick = 'sl_primary_cta',
  WidgetSecondaryClick = 'sl_secondary_cta',
  FlowChanged = 'sl_flow_start',
  FlowEnd = 'sl_flow_end',
  PageChanged = 'sl_page_view',
  LeadCaptured = 'sl_lead_identify',
  OpenedExternalUrl = 'sl_open_external_url',
  FlowlistItemClick = 'sl_checklist_item_view',
  DemoFinished = 'sl_demo_finished',
  RecordingStart = 'sl_demo_recording_start',
  CustomCta = 'sl_convert_cta',
}

export const CrossOriginSessionEventMessage = 'storylane-demo-event';

export const CrossOriginTokenSubmitMessage = 'storylane-token-submit';
export const CrossOriginIdentifyUserMessage = 'storylane-identify-user';
export const CrossOriginEmbedDemoEvent = 'storylane-embed-demo';
export const CrossOriginDemoHostSource = 'storylane-demo-host';
export const CrossOriginHostInfoEvent = 'storylane-host-info';
export const CrossOriginTrackingSource = 'storylane-tracking';
export const AudioAutoplaySessionKey = 'autoplay-widget-audio';

export const LeadSessionStorageKey = 'lid';

export const ProjectErrorCode = {
  PROJECT_EXPIRED: 'project_link_expired',
  PROJECT_PROTECTED: 'project_link_protected',
  PROJECT_LINK_EMAIL_REQUIRED: 'project_link_email_required',
};

export const rootContainerId = 'sl-demo-root';

export const enum AnalyticsSessionEventValue {
  Opened = 'opened',
  Viewed = 'viewed',
  Closed = 'closed',
  WidgetViewed = 'widget_viewed',
  LeadCaptured = 'lead_captured',
  PageViewed = 'page_viewed',
  CtaOpened = 'cta_opened',
  FlowStarted = 'flow_started',
  FlowEnded = 'flow_ended',
  FlowlistCompleted = 'flowlist_completed',
}
