import { useCallback, ReactNode, VFC } from 'react';

import styles from './FullScreenAlert.module.css';
import Button from '../Button';

type FullScreenAlertProps = {
  icon: ReactNode;
  primaryText: string;
  secondaryText: string | ReactNode;
  skipButtonText?: string;
  hasBack?: boolean;
  hasSkipButton?: boolean;
  onSkipClick?: () => void;
  testId?: string;
};

const FullScreenAlert: VFC<FullScreenAlertProps> = ({
  icon,
  primaryText,
  secondaryText,
  skipButtonText,
  hasSkipButton = false,
  hasBack = false,
  onSkipClick,
  testId,
}) => {
  const handleBackClick = useCallback(() => {
    window.history.back();
  }, []);

  return (
    <div data-testid={testId} className={styles.wrapper}>
      {hasBack && (
        <div className={styles.backTopButtonContainer}>
          <Button onClick={handleBackClick} variant="inline" icon="long-arrow">
            back
          </Button>
        </div>
      )}
      <div className={styles.image}>{icon}</div>
      <div className={styles.mainText}>{primaryText}</div>
      <div className={styles.secondaryText}>{secondaryText}</div>
      {hasSkipButton && (
        <div className={styles.skipButtonContainer}>
          <Button variant="outline" size="lg" onClick={onSkipClick}>
            {skipButtonText || 'skip'}
          </Button>
        </div>
      )}
      {hasBack && (
        <div className={styles.backButtonContainer}>
          <Button onClick={handleBackClick} variant="inline">
            take me back
          </Button>
        </div>
      )}
    </div>
  );
};

export default FullScreenAlert;
