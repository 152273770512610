export const getSearchParams = (asPath: string): URLSearchParams => {
  const queryString = asPath.split('?')[1] || '';

  // Manually create a query string and parse it
  const queryParams = new URLSearchParams(
    queryString.startsWith('?') ? queryString : `?${queryString}`
  );

  return queryParams;
};

export default getSearchParams;
